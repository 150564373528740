<template>
  <section class="authenticity-page">
    <header class="authenticity-page__header">
      <img src="../../assets/images/logo.svg" alt="Logo Sistransce" class="authenticity-page__logo" />      
    </header>
    <h2 class="authenticity-page__title">
      Autenticidade - Laudo de Autovistoria
    </h2>
    <article class="authenticity-page__content">
      <div v-if="recaptcha.verified">
        <header>
          <h4 class="authenticity-page__info">
            Veículo:
            {{ autovistoria.plate }}
          </h4>
          <h4 class="authenticity-page__info" v-if="autovistoria.original_report_url">
            Data de emissão: 
            {{ autovistoria.emission_at | date }}
          </h4>   
       
        </header>
        <v-alert
        v-if="autovistoria.error"
          border="left"
          color="red"
          dense
          icon="error"
          type="error"
        >
        Não encontramos laudo para o veículo informada.
        </v-alert>        
      </div>    
      <vue-recaptcha
        v-else
        :sitekey="recaptcha.key"        
        @verify="onVerify"
        class="authenticity-page__recaptcha"
      />
      <v-skeleton-loader v-if="autovistoria.isLoading" type="card"></v-skeleton-loader>
      <iframe 
        v-if="autovistoria.original_report_url" 
        class="authenticity-page__iframe" 
        :src="mountSrcUrl()" frameborder="0"></iframe>
    </article>
  </section>
</template>

<script>
import InspectionService from '@/services/inspection.service';
import autovistoria from '@/store/modules/autovistoria';
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: { VueRecaptcha },
  data() {
    return {
      recaptcha: {
        key: '6LfjQPIqAAAAABPrtkvwwfDpWts-Gm_Z8JfliRzG',
        verified: false
      },      
      autovistoria: {
        plate: null,
        original_report_url: null,
        deadline_at: null,
        isLoading: false,
        error: false
      }      
    };
  },  
  methods: {
    onVerify(response) {
      this.recaptcha.verified = true
      this.loadAuthenticityInspection()
    },
    extractQueryParams() {
      const { placa, autovistoria, laudo } = this.$route.query
      this.autovistoria = {...this.autovistoria, plate: placa}

      return { placa, autovistoria, laudo }
    },
    mountSrcUrl() {
      const { original_report_url } = this.autovistoria
      return `https://docs.google.com/gview?url=${original_report_url}&embedded=true`
    },
    loadAuthenticityInspection() {
      const { placa, autovistoria, laudo } = this.$route.query
      this.extractQueryParams()

      const params = {
        key: autovistoria,
        plate: placa,
        original_report_key: laudo
      }      

      this.autovistoria =  {...this.autovistoria, isLoading: true, error: false}

      InspectionService.authenticity(params).subscribe(({data}) => {
        this.autovistoria = data;      
        setTimeout(() => {
          this.autovistoria =  {...this.autovistoria, isLoading: false, error: false}
        }, 2000)        
      }, (error) => {
        this.autovistoria =  {...this.autovistoria, isLoading: false, error: true}
      })
    }
  },
}
</script>

<style lang="scss">
.authenticity-page {
  &__header {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-bottom: solid 1px rgba(0,0,0, 0.1);
  }

  &__title {
    text-align: center;
    font-size: 20px !important;
    margin: 10px auto 0px auto;
    font-weight: 500 !important;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
  }

  &__logo {
    width: 200px;
  }

  &__content {
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: white;
    max-width: 1000px;    
    margin: 10px auto;
    padding: 10px 30px;    
    
    header {
      display: flex;
      justify-content: space-around;
    }
  }

  &__info {
    text-align: center;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500 !important;
    margin: 0px 0px 10px 0px;

    i {
      color: black !important;
    }
  }

  &__recaptcha {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
  }

  &__iframe {
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.1);
    height: 1000px;
  }

}
</style>