import { BaseAPI } from '@/api/base.api'
import Axios, { AxiosObservable } from 'axios-observable';
import axios, { AxiosInstance } from 'axios'

export class InspectionAPI extends BaseAPI<any> {
  constructor() {
    super();
    this.axios = new Axios(axios.create({}));
    this.apiUrl = `${this.apiUrl}/api_app/v1/inspections`;
  }

  public authenticity(params: any): AxiosObservable<any> {
    return this.axios.get(`${this.apiUrl}/authenticity`, { params: params });
  }
}
